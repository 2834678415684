import {AxiosResponse} from "axios";
import {apiClient} from "@/modules/apiClient";
import {EmergencyContact} from "@/models/user/EmergencyContact";
import {EmergencyContactCommand} from "@/models/user/EmergencyContactCommand";

export const EmergencyContactService = {
    list(): Promise<AxiosResponse<Array<EmergencyContact>>> {
        return apiClient.get('/emergencycontacts')
    },
    add(emergencyContact: EmergencyContactCommand): Promise<AxiosResponse<EmergencyContact>> {
        return apiClient.post('/emergencycontacts', emergencyContact);
    },
    update(emergencyContact: EmergencyContact): Promise<AxiosResponse> {
        return apiClient.put(`/emergencycontacts/${emergencyContact.emergencyContactId}`, emergencyContact)
    },
    remove(emergencyContactId: string): Promise<AxiosResponse> {
        return apiClient.delete(`/emergencycontacts/${emergencyContactId}`)
    }
}
