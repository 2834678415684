
import {defineComponent, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import Button from "@/components/UI/Button.vue";
import InputGroup from "@/components/UI/InputGroup.vue";
import {EmergencyContact} from "@/models/user/EmergencyContact";
import {EmergencyContactService} from "@/services/EmergencyContactService";
import {EmergencyContactCommand} from "@/models/user/EmergencyContactCommand";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {bus} from "@/modules/eventBus";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  setup(_, {emit}) {
    const modal = ref<InstanceType<typeof ModalBase>>();
    let entity = ref<EmergencyContact>({} as EmergencyContact);
    let mode = ref<string>('add');
    let contactPhone = ref<string>('');
    const invokeUpdate = (_entity: EmergencyContact) => {
      entity.value = _entity;
      contactPhone.value = _entity.contact;
      mode.value = 'update';
      modal.value?.toggle();
    }
    const invokeAdd = () => {
      entity.value = {} as EmergencyContact;
      contactPhone.value = '';
      mode.value = 'add';
      modal.value?.toggle();
    }
    const toggleClosed = () => {
      modal.value?.toggle();
    }
    const emitAdded = (contact: EmergencyContact) => {
      emit('added', contact)
      bus.emit('onboarding:emit:emergencycontacts')
      modal.value?.toggle();
    }
    return {modal, invokeUpdate, invokeAdd, entity, mode, toggleClosed, emitAdded, contactPhone, v$: useVuelidate()}
  },
  components: {InputGroup, Button, ModalBase},
  emits: ['added'],
  data(){
    return {
      isLoading: false,
    }
  },
  mounted(){
    bus.on('modals:emergency:add', this.invokeAdd)
  },
  validations(){
    return {
      entity: {
        name: {required},
        relationship: {required},
        contact: {required}
      }
    }
  },
  methods: {
    add() {
      this.v$.entity.$touch()
      if(!this.v$.entity.$invalid) {
        this.isLoading = true
        EmergencyContactService.add({
          contact: this.entity.contact,
          name: this.entity.name,
          relationship: this.entity.relationship
        } as EmergencyContactCommand).then(response => {
          Swal.fire("Emergency Contact Added")
          this.emitAdded(response.data)
          WebAnalytics.trackFlexible('Added Emergency Contact', {})
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
    update() {
      this.v$.entity.$touch()
      if(!this.v$.entity.$invalid) {
        this.isLoading = true
        EmergencyContactService.update(this.entity).then(() => {
          Swal.fire("Emergency Contact Updated")
          this.toggleClosed()
          WebAnalytics.trackFlexible('Updated Emergency Contact', {})
        }).finally(() => {
          this.isLoading = false
        })
      }
    }
  },
  computed: {
    title(): string {
      return this.mode === 'add' ? 'Add New Emergency Contact' : `Manage ${this.entity.name}`
    }
  }
})
