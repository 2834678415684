
import {defineComponent, ref} from "vue";
import EmergencyContactModal from "@/components/modals/EmergencyContactModal.vue";
import {EmergencyContact} from "@/models/user/EmergencyContact";
import {EmergencyContactService} from "@/services/EmergencyContactService";
import Swal from "sweetalert2";
import Button from "@/components/UI/Button.vue";
import {useToast} from "vue-toastification";
import {ProblemDetails} from "@/models/ProblemDetails";

export default defineComponent({
  components: {Button, EmergencyContactModal},
  setup() {
    const emergencyContactModal =
        ref<InstanceType<typeof EmergencyContactModal>>();
    const invokeAddEmergencyContact = () => {
      emergencyContactModal.value?.invokeAdd();
    };
    const invokeUpdateEmergencyContact = (_contact: EmergencyContact) => {
      emergencyContactModal.value?.invokeUpdate(_contact);
    };
    const toast = useToast();
    return {emergencyContactModal, invokeAddEmergencyContact, invokeUpdateEmergencyContact, toast}
  },
  data() {
    return {
      emergencyContacts: [] as Array<EmergencyContact>,
      isFetching: true
    }
  },
  mounted() {
    this.fetchContacts()
  },
  methods: {
    fetchContacts() {
      EmergencyContactService.list().then(response => {
        this.emergencyContacts = response.data
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      }).finally(() => {
        this.isFetching = false
      })
    },
    addToList(contact: EmergencyContact) {
      this.emergencyContacts.push(contact)
    },
    removeContact(emergencyContactId: string) {
      Swal.fire({
        title: "Are you sure?",
        confirmButtonText: 'Remove Contact',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Keep Contact'
      }).then(response => {
        if (response.isConfirmed) {
          this.isFetching = true
          EmergencyContactService.remove(emergencyContactId).then(() => {
            this.fetchContacts()
          }).catch(err => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
          }).finally(() => {
            this.isFetching = false
          })
        }
      })
    },
    formatPhone(phone: string) {
      return phone.replace(/[^0-9]/g, '')
          .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
    }
  }
})
