import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-none d-md-flex justify-content-center mt-8" }
const _hoisted_2 = { class: "d-flex d-md-none justify-content-center mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ModalBase = _resolveComponent("ModalBase")!
  const _directive_maska = _resolveDirective("maska")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_ModalBase, {
    title: "Manage Emergency Contact",
    size: "modal-fullscreen-sm-down",
    ref: "modal"
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1)
      ]),
      _createVNode(_component_InputGroup, {
        label: "Name",
        "is-required": true
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: _normalizeClass(["form-control", {'is-invalid': _ctx.v$.entity.name.$error}]),
            placeholder: "Jane Smith",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.name) = $event))
          }, null, 2), [
            [_vModelText, _ctx.entity.name]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_InputGroup, {
        label: "Relation",
        "is-required": true
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: _normalizeClass(["form-control", {'is-invalid': _ctx.v$.entity.relationship.$error}]),
            placeholder: "Mother",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.relationship) = $event))
          }, null, 2), [
            [_vModelText, _ctx.entity.relationship]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_InputGroup, {
        label: "Phone Number",
        "is-required": true
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: _normalizeClass(["form-control", {'is-invalid': _ctx.v$.entity.contact.$error}]),
            placeholder: "1 (555) 827-1234",
            onMaska: _cache[2] || (_cache[2] = ($event: any) => (_ctx.entity.contact = $event.target.dataset.maskRawValue)),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contactPhone) = $event))
          }, null, 34), [
            [_directive_maska, `1 (###) ###-####`],
            [_vModelText, _ctx.contactPhone]
          ])
        ]),
        _: 1
      }),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.mode === 'update')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              type: "primary",
              onClick: _ctx.update,
              "is-loading": _ctx.isLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode("Save Changes")
              ]),
              _: 1
            }, 8, ["onClick", "is-loading"]))
          : _createCommentVNode("", true),
        (_ctx.mode === 'add')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              type: "primary",
              onClick: _ctx.add,
              "is-loading": _ctx.isLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode("Add Contact")
              ]),
              _: 1
            }, 8, ["onClick", "is-loading"]))
          : _createCommentVNode("", true)
      ])), [
        [_directive_loading, _ctx.isLoading]
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_Button, {
          type: "secondary",
          class: "btn-sm me-4",
          onClick: _ctx.toggleClosed,
          "is-loading": _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        }, 8, ["onClick", "is-loading"]),
        (_ctx.mode === 'update')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              type: "primary",
              class: "btn-sm",
              onClick: _ctx.update,
              "is-loading": _ctx.isLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode("Save Changes")
              ]),
              _: 1
            }, 8, ["onClick", "is-loading"]))
          : _createCommentVNode("", true),
        (_ctx.mode === 'add')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              type: "primary",
              class: "btn-sm",
              onClick: _ctx.add,
              "is-loading": _ctx.isLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode("Add Contact")
              ]),
              _: 1
            }, 8, ["onClick", "is-loading"]))
          : _createCommentVNode("", true)
      ])), [
        [_directive_loading, _ctx.isLoading]
      ])
    ]),
    _: 1
  }, 512))
}